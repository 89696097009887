<template>
  <div>
    <Row :gutter="6" class="m-b-10">
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select
          size="small"
          placeholder="类型"
          filterable
          clearable
          v-model="query.typeList"
        >
          <i-option
            :value="1"
            >储值</i-option
          >

          <i-option
            :value="2"
            >消耗</i-option
          >
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select
          size="small"
          placeholder="乙方"
          filterable
          v-model="query.secondPartyId"
        >
          <i-option
            :value="item.id"
            v-for="item in channellist"
            :key="item.id"
            >{{
              item.name
            }}</i-option
          >
        </i-select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker
          size="small"
          placeholder="发生时间"
          v-model="schedule"
          type="daterange"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <i-input
          size="small"
          placeholder="关键字：支持名称查询"
          v-model="query.keyword"
        ></i-input>
      </i-col>
      <i-col span="4" class="m-b-5">
        <i-button
          size="small"
          class="m-r-5"
          type="primary"
          icon="ios-search"
          @click="handleSearch()"
          >搜索</i-button
        >
      </i-col>
    </Row>

    <Table size="small" stripe :data="list" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
    <Modal v-model="showStatementDetail" :width="800" footer-hide>
      <statement-detail-component ref="statementDetail"  v-if="statementId" :id="statementId" isApproval  />
    </Modal>
  </div>
</template>

<script>
import statementDetailComponent from '@/components/statement/StatementDetailComponent'
import { ParseDate } from '@/utils/dateFormat'
/** new api */
import { getCompanyChild } from '@/api/os/company'
import { getContractRecordPage } from '@/api/scp/storedContract'
export default {
  components: {
    statementDetailComponent
  },
  data () {
    return {
      channellist: [],
      statusArray: [],
      schedule: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: ''
      },
      showStatementDetail: false,
      statementId: 0,
      list: [],
      tableColumns: [
        { title: '编号', align: 'center', key: 'code' },
        { title: '类型', align: 'center', key: 'typeName' },
        { title: '乙方', align: 'center', key: 'secondPartyName' },
        {
          title: '库存量',
          align: 'center',
          renderHeader: (h, params) => {
            return h('div', [
              h('span', '媒体库存量'),
              h('Icon', {
                props: { type: 'md-help-circle', size: 18, color: '#2d8cf0', id: 'serviceIon' },
                style: { 'margin-left': '4px', cursor: 'pointer' },
                on: {
                  click: (event) => {
                    if (document.getElementsByClassName('serviceRemark').length < 1) {
                      var serviceDiv = document.createElement('div')
                      serviceDiv.className = 'serviceRemark'

                      // const title = document.createElement('p')
                      // title.innerHTML = '计费时间是指：'
                      // serviceDiv.appendChild(title)

                      const content = document.createElement('p')
                      content.innerHTML = '电子媒体库存单位为：秒，其他媒体为：块*天'
                      serviceDiv.appendChild(content)

                      event.target.parentElement.appendChild(serviceDiv)
                      // 3秒后自动关闭
                      setTimeout(() => {
                        if (serviceDiv.parentNode) {
                          serviceDiv.parentNode.removeChild(serviceDiv)
                        }
                      }, 3000)
                    } else {
                      const serviceDivRemark = document.getElementsByClassName('serviceRemark')
                      serviceDivRemark[0].parentNode.removeChild(serviceDivRemark[0])
                    }
                  }
                }
              })
            ])
          },
          key: 'productVoList',
          render: (h, params) => {
            const spans = []
            params.row.productVoList.forEach(resource => {
              spans.push(h(
                'span',
                resource.productName
              ))
              spans.push(h(
                'span',
                '('
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.quantity
              ))
              spans.push(h(
                'span',
                ')'
              ))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },

        {
          title: '服务库存量',
          align: 'center',
          key: 'serviceFeeVOList',
          render: (h, params) => {
            const spans = []
            params.row.serviceFeeVOList.forEach(resource => {
              spans.push(h(
                'span',
                resource.serviceItemName
              ))
              spans.push(h(
                'span',
                '('
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.signServiceQuantity
              ))
              spans.push(h(
                'span',
                ')'
              ))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        {
          title: '交易合同编号',
          key: 'contractCode',
          render: (h, params) => {
            return h('div', [
              params.row.type === 2
                ? h(
                  'a',
                  {
                    on: {
                      click: () => {
                        this.statementId = params.row.versionId
                        this.showStatementDetail = true
                      }
                    }
                  },
                  params.row.contractCode
                ) : h(
                  'label',
                  '-'
                )
            ])
          }
        },
        { title: '发生时间', align: 'center', key: 'createTime' },
        {
          title: '状态',
          align: 'center',
          render: (h, params) => {
            return h(
              'span',
              `${params.row.statusName} `
            )
          }
        }
      ]
    }
  },
  created () {
    this.getCompanyArray()
  },
  methods: {
    getCompanyArray () {
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array
        if (this.channellist.length) {
          this.query.secondPartyId = this.channellist[0].id
          this.$store.commit('set_storedValue_secondPartyId', this.query.secondPartyId)
        }
        this.getPage()
      })
    },

    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    // 分页
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.getPage()
    },
    // 搜索
    handleSearch () {
      this.query.startDate = this.schedule[0] ? ParseDate(this.schedule[0]) : null
      this.query.endDate = this.schedule[1] ? ParseDate(this.schedule[1]) : null
      this.query.pageNumber = 1
      this.$store.commit('set_storedValue_secondPartyId', this.query.secondPartyId)
      // this.$store.commit('set_cur_category', null)
      // this.$store.dispatch('getDeviceStatusStaData', { assetId: this.query.assetId, devicemodel: this.query.devicemodel, stationId: this.query.stationId })
      this.getPage()
      // this.getSvgData()
    },
    // 获取分页列表
    getPage () {
      getContractRecordPage(this.query).then((res) => {
        if (res && !res.errcode) {
          // res.list.map((item) => {
          //   item.visible = item.visibleScope === 2
          //   return item
          // })
          this.list = res.list
          this.total = res.totalRow
        } else {
          this.list = []
          this.total = 0
        }
      })
    }
  }
}
</script>
